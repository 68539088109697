<style lang="scss">
    @import "~@/assets/css/var";
    .status_normal {
        display: inline-block;
        line-height: 23px;
        border: 1px solid #999999;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        padding: 0 13px;
        &.pass {
            color: $main;
            border-color: $main;
        }
        &.stop {
            color: $red;
            border-color: $red;
        }
    }
</style>

<template>
    <div class="common-table">
        <div class="common-table__title">
            上传记录
            <slot></slot>
            <ts-table-operate v-if="cols.length > 0" :cols="cols" @handleFilter="handleFilter"
                              @handleSort="sort"></ts-table-operate>
        </div>

        <div class="ts-table flex flex-item flex-column">
            <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
                      @handleSort="sort" :error="pageParam.error" :error-code="error" full
                      @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
                <template v-slot:slot_operate="{props}">
                    <template v-if="props.row.status === 0">
                        <span class="status_normal">已上传</span>
                    </template>
                    <template v-else-if="props.row.status === 1">
                        <span class="status_normal pass">审核通过</span>
                    </template>
                    <template v-else>
                        <span class="status_normal stop">已驳回</span>
                    </template>
                </template>
            </ts-table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['table'],
        watch: {
            table: {
                immediate: true,
                handler() {
                    this.query = {}
                    this.init()
                },
            },
        },
        data() {
            return {
                loading: false,
                list: [],
                error: '',
                cols: [
                    {
                        fieldName: 'publishDate',
                        name: '时间',
                        maxWidth: 150,
                        listView: '是',
                        detailView: '是',
                    },
                    {
                        fieldName: 'title',
                        name: '标题',
                        listView: '是',
                        detailView: '是',
                    },

                    {
                        fieldName: 'organization',
                        name: '单位机构',
                        detailView: '是',
                    },
                    {
                        fieldName: 'articleKeyword',
                        name: '关键字/版本',
                        detailView: '是',
                    },
                    {
                        fieldName: 'department',
                        name: '科室',
                        detailView: '是',
                    },
                    {
                        fieldName: 'disease',
                        name: '病症',
                        detailView: '是',
                    },
                    {
                        fieldName: 'diagnosisMethod',
                        name: '诊疗方法',
                        detailView: '是',
                    },
                    {
                        fieldName: 'diagnosisPoint',
                        name: '诊疗要点',
                        detailView: '是',
                    },
                    {
                        fieldName: 'conclusion',
                        name: '结论',
                        detailView: '是',
                    },
                    {
                        fieldName: 'fileUrl',
                        name: 'PDF文件',
                        detailView: '是',
                        type: this.$variable.searchType.LIKE,
                        toFileType: '文件'
                    },
                    {
                        fieldName: 'contentText',
                        name: '内容',
                        detailView: '是',
                        toFileType: '富文本'
                    },
                    {
                        id: '1',
                        fieldName: 'products',
                        name: '产品',
                        listView: '是',
                        detailView: '是',
                        listQueryView: '是',
                        listQueryModel: 0,
                    },
                    {
                        id: '2',
                        fieldName: 'status',
                        name: '状态',
                        detailView: '是',
                        listQueryView: '是',
                        listQueryModel: 1,
                        single: true,
                        useKey: true,
                        list: [
                            {key: 0, value: '已上传'},
                            {key: 1, value: '审核通过'},
                            {key: 2, value: '已驳回'},
                        ],
                    },
                    {
                        fieldName: 'failReason',
                        name: '不通过原因',
                        detailView: '是',
                    },
                    {
                        fieldName: 'operate',
                        name: '操作',
                        listView: '是',
                        maxWidth: 60,
                        listClass: 'tc'
                    },
                ],
                searchCols: [],
                pageParam: {
                    page: 1,
                    pageSize: this.$variable.basePageSize,
                    total: 0,
                    error: '',
                },
                sortParam: {},
                query: {},

                // 详情弹窗
                drawer: {
                    show: false,
                    id: '',
                },
            }
        },
        methods: {
            init() {
                this.pageParam.page = 1
                this.getList()
            },
            getList() {
                this.loading = true
                this.$api.post(`sys/${this.table}`, this.$help.joinObj({
                    page:{
                        pageNo: this.pageParam.page,
                        pageSize: this.pageParam.pageSize,
                    },
                    sort: this.sortParam
                }, {params: this.query}))
                    .then(res => {
                        if (res.code !== '00000') {
                            this.error = 405;
                        } else {
                            if (this.pageParam.page === 1 && res.data.records.length === 0) {
                                //debugger
                                this.$emit('empty')
                            }
                            this.list = res.data.records;
                            this.pageParam.total = res.data.totalCount;
                            //this.pageParam.error = res.message;
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            handleFilter(data) {
                this.query = {}
                this.pageParam.page = 1
                data.forEach(item => {
                    this.query[item.field.fieldName] = item.model
                })
                this.getList()
            },
            handleSizeChange(val) {
                this.pageParam.pageSize = val
                this.handleCurrentChange(1)
            },
            handleCurrentChange(page) {
                this.pageParam.page = page
                this.getList()
            },
            sort() {},
        },
    }
</script>